import {
    CategoryFilterOverlay,
    CategoryPageComponent as SourceCategoryPageComponent,
    CategorySort,
    FilterIcon,
    GridIcon,
    ListIcon,
} from 'SourceRoute/CategoryPage/CategoryPage.component';
import { ReactElement } from 'Type/Common.type';
import { isCrawler, isSSR } from 'Util/Browser';

import './CategoryPage.override.style';

export {
    CategoryFilterOverlay,
    FilterIcon,
    GridIcon,
    ListIcon,
    CategorySort,
};

/** @namespace Pwa/Route/CategoryPage/Component */
export class CategoryPageComponent extends SourceCategoryPageComponent {
    renderMiscellaneous(): ReactElement {
        return (
            <aside block="CategoryPage" elem="Miscellaneous">
                { this.renderItemsCount() }
                <div
                  block="CategoryPage"
                  elem="MiscellaneousLayoutWrapper"
                >
                  <div
                    block="CategoryPage"
                    elem="LayoutWrapper"
                    mods={ { isPrerendered: isSSR() || isCrawler() } }
                  >
                      { /* { this.renderLayoutButtons() } */ }
                      { this.renderCategorySort() }
                  </div>
                  <div
                    block="CategoryPage"
                    elem="LayoutWrapper"
                    mods={ { isPrerendered: isSSR() || isCrawler() } }
                  >
                      { this.renderFilterButton() }
                  </div>
                </div>
            </aside>
        );
    }
}

export default CategoryPageComponent;
