import { toggleOverlayByKey } from '@scandipwa/scandipwa/src/store/Overlay/Overlay.action';
import { Dispatch } from 'redux';

import { ADD_TO_CART_POPUP_ID } from 'Component/AddToCartPopup/AddToCartPopup.config';
import CartQuery from 'Query/Cart.query';
import {
    CartDispatcher as SourceCartDispatcher,
} from 'SourceStore/Cart/Cart.dispatcher';
import { AddProductToCartOptions } from 'Store/Cart/Cart.type';
import { showNotification } from 'Store/Notification/Notification.action';
import { NotificationType } from 'Store/Notification/Notification.type';
import { NetworkError } from 'Type/Common.type';
import { getCartId } from 'Util/Cart';
import { getErrorMessage } from 'Util/Request/Error';
import { fetchMutation } from 'Util/Request/Mutation';

/** @namespace Pwa/Store/Cart/Dispatcher */
export class CartDispatcher extends SourceCartDispatcher {
    async addProductToCart(
        dispatch: Dispatch,
        options: AddProductToCartOptions,
    ): Promise<void> {
        const { products = [], cartId: userCartId } = options;

        const cartId = userCartId || getCartId();

        if (!Array.isArray(products) || products.length === 0) {
            dispatch(showNotification(NotificationType.ERROR, __('No product data!')));

            return Promise.reject();
        }

        try {
            if (!cartId) {
                return await Promise.reject();
            }

            const { addProductsToCart: { user_errors: errors = [] } = {} } = await fetchMutation(
                CartQuery.getAddProductToCartMutation(cartId, products),
            );

            if (Array.isArray(errors) && errors.length > 0) {
                errors.forEach((error) => {
                    dispatch(showNotification(NotificationType.ERROR, getErrorMessage(error)));
                });

                return await Promise.resolve();
            }

            await this.updateInitialCartData(dispatch);

            if (Array.isArray(products) && products.length === 1) {
                dispatch(toggleOverlayByKey(ADD_TO_CART_POPUP_ID));
            }
            // dispatch(showNotification(NotificationType.SUCCESS, __('Product was added to cart!')));
        } catch (error) {
            if (!navigator.onLine) {
                dispatch(showNotification(NotificationType.ERROR, __('Not possible to fetch while offline')));

                return Promise.reject();
            }

            dispatch(showNotification(NotificationType.ERROR, getErrorMessage(error as NetworkError)));

            return Promise.reject();
        }

        return Promise.resolve();
    }
}

export default new CartDispatcher();
